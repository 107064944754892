<template>
  <div class="outer">
    <div class="map-preview-background" :style="mapStyle" />
    <div class="info-wrapper">
      <div class="info-panel">
        <p>Bitte akzeptieren Sie die Marketing-Cookies, um die Karte anzuzeigen.</p>
        <button class="button button-primary" @click="acceptCookies">Marketing-Cookies akzeptieren</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CookieInfo",
  computed: {
    ...mapGetters(["mapFilename"]),
    mapStyle() {
      return {
        backgroundImage: 'url(' + require(`@/assets/${this.mapFilename}`) + ')',
      };
    },
  },
  methods: {
    acceptCookies() {
      Cookiebot.submitCustomConsent(Cookiebot.consent.preferences , Cookiebot.consent.statistics, true)  // eslint-disable-line no-undef

      // Notify about cookie acceptance
      this.$emit("marketing-cookies-accepted");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/theme";

div.outer {
  position: relative;
  height: 100%;
}

div.map-preview-background {
  position: absolute;

  height: 100%;
  width: 100%;

  background-size: cover;
  background-position: center;
  filter: blur(2px);
  opacity: 0.25;
}

div.info-wrapper {
  position: relative;

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.info-panel {
  width: 100%;
  padding: 2em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
